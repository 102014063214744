import '../libs/phone-mask/jquery.inputmask.bundle.min';
import '../libs/phone-mask/jquery.inputmask-multi.min';

const maskList = $.masksSort($.masksLoad('/js/json/phone-codes.json'), ['#'], /[0-9]|#/, 'mask');
const maskOpts = {
    inputmask: {
        definitions: {
            '#': {
                validator: '[0-9]',
                cardinality: 1
            }
        },
        showMaskOnHover: false,
        autoUnmask: true
    },
    match: /[0-9]/,
    replace: '#',
    list: maskList,
    listKey: 'mask',
    onMaskChange: function (maskObj, completed) {
        if (completed) {
            let hint = maskObj.name_ru;
            if (maskObj.desc_ru && maskObj.desc_ru !== '') {
                hint += ` (${maskObj.desc_ru})`;
            }
            $('#phone-country').html(hint);
        } else {
            $('#phone-country').html('');
        }
        $(this).attr('placeholder', 'Номер телефона');
    }
};


$('.form-block__input-phone').inputmasks(maskOpts);
