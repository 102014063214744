import 'magnific-popup';
import '../phone-mask';

import initPopup, { openDynamicPopup } from '../../blocks/modules/_common/popup/popup';
import { onInputFocus, onInputTooltip, togglePasswordType, hideNavBar } from '../../blocks/modules/_common/form/form';
import {
    showErrorMessages,
    hideAllErrorMessage,
    validateLogin,
    validatePassword,
    validatePhone,
    validatePhonePassword,
    loginAjax,
    showCaptcha
} from '../../blocks/modules/_common/login/login';
import { checkCounter } from '../../blocks/modules/_common/form/__sms-limit/sms-limit';
import { loadCaptcha } from '../../blocks/modules/_common/form/__captcha/form__captcha';
import { serializeForm, cookieUtils, handleTabSelect } from '../utils';
import { COUPON_COOKIE } from '../constants';

onInputFocus();
onInputTooltip();
togglePasswordType();
initPopup();
serializeForm($);
loadCaptcha();
hideNavBar();


$(document).ready(() => {
    if (isNeedCaptcha) {
        showCaptcha();
    }
});

if (localStorage.getItem('reg_number')) {
    document.getElementById('login-phone').value = localStorage.getItem('reg_number');
    localStorage.removeItem('reg_number');

    document.getElementById('password-phone').focus();
}

$('#form_log_mail, #form_login-returned').submit(function (e) {
    e.preventDefault();
    hideAllErrorMessage();

    const login = document.getElementById('login');
    const password = document.getElementById('password');
    const captcha = document.getElementById('captcha');
    const isValid = validateLogin(login) && validatePassword(password, false);

    if (isValid) {
        const data = {};
        data.login = login.value;
        data.password = password.value;
        if (captcha.value) {
            data.captcha = captcha.value;
        }
        const isUserHasCoupon = cookieUtils.get(COUPON_COOKIE) || false;
        loginAjax(data, false, isUserHasCoupon);
    }
});

$('#form_log_phone').submit(function (e) {
    e.preventDefault();
    hideAllErrorMessage();

    const phoneField = document.getElementById('login-phone');
    const passwordField = document.getElementById('password-phone');
    const captchaField = document.getElementById('captcha-phone');
    const isValid = validatePhone(phoneField) && validatePhonePassword(passwordField) ;

    if (isValid) {
        const login = phoneField.value;
        const password = passwordField.value;
        const captcha = captchaField.value ? captchaField.value : '';
        const isUserHasCoupon = cookieUtils.get(COUPON_COOKIE) || false;

        loginAjax({ login, password, captcha }, false, isUserHasCoupon, true);
    }
});


[...document.querySelectorAll('input[type=radio]')].forEach((input) => {
    input.addEventListener('change', function(e) {
        handleTabSelect(e.target.id);
    })
})
